import Container from "../components/Container";
import Services from "../components/Services";
import Product from "../components/Product";
import About from "../components/About";
import Partners from "../components/Partners";
import Subscribe from "../components/Subscribe";
import Testimonial from "../components/Testimonial";
import Footer from "../components/Footer";
import Popup from "../components/Popup";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";

function Home() {
  const [count, setCount] = useState(() => {
    let text = ["Solutions", "Recruiting", "Training"];
    let x = 1;
    setInterval(() => {
      setCount(text[x]);
      x += 1;
      if (x === 3) {
        x = 0;
      }
    }, 1500);
  });

  const [color, setColor] = useState(() => {
    let change = ["#fdc329", "#90FF5B", "#e39ff6"];
    let y = 1;
    setInterval(() => {
      setColor(change[y]);
      y += 1;
      if (y === 3) {
        y = 0;
      }
    }, 1500);
  });

  const [buttonPop, setButtonPop] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setButtonPop(true);
    }, 120000);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Container text={count} design={color}/>
      <Popup trigger={buttonPop} setTrigger={setButtonPop} />
      <Services />
      <Product />
      <About />
      <Partners />
      <Subscribe />
      <Testimonial />
      <Footer />
    </motion.div>
  );
}

export default Home;

import React from "react";
import Home from "../pages/Home";
import Load from "./Load";
import "../styles/About.css";
import "../styles/Community.css";
import "../styles/Contact.css";
import "../styles/Container.css";
import "../styles/Faq.css";
import "../styles/Footer.css";
import "../styles/Partners.css";
import "../styles/Popup.css";
import "../styles/Portfolio.css";
import "../styles/Product.css";
import "../styles/Services.css";
import "../styles/Subscribe.css";
import "../styles/Testimonial.css";
// import ReactGA from 'react-ga';
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
const LazyHome = React.lazy(()=>import("../pages/Home"));
const LazyAbout = React.lazy(() => import("../pages/AboutUs"));
const LazyContact = React.lazy(() => import("../pages/ContactUs"));
const LazyProduct = React.lazy(() => import("../pages/Product"));
const LazyPortfolio = React.lazy(() => import("../pages/Portfolio"));
const LazyProjOut = React.lazy(() => import("../pages/ProjOut"));
// const LazyWeb = React.lazy(() => import("../pages/WebDev"));
const LazyTechResource = React.lazy(() => import("../pages/TechResource"));
const LazyCapacity = React.lazy(() => import("../pages/Capacity"));
const LazyFAQ = React.lazy(() => import("../pages/FAQ"));
const LazyCommunity = React.lazy(() => import("../pages/Community"));
const LazyPrivacy = React.lazy(()=> import("../pages/Privacy"));
const LazyAppointment = React.lazy(()=> import("../pages/Appointment"));
// const TRACKING_ID = "UA-XXXXX-X";
// ReactGA.initialize(TRACKING_ID);

export default function AnimatedRouter() {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route 
          exact 
          path="/" 
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyHome />
            </React.Suspense>
          } 
        />
        <Route
          exact
          path="/community"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyCommunity />
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/about-us"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyAbout />
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/product"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyProduct />
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/portfolio"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyPortfolio />
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/tech_studio"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyProjOut />
            </React.Suspense>
          }
        />
        {/* <Route
          exact
          path="/web_&_software_development"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyWeb />
            </React.Suspense>
          }
        /> */}
        <Route
          exact
          path="/tech_recruiting"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyTechResource />
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/tech_training"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyCapacity />
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/faq"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyFAQ />
            </React.Suspense>
          }
        />
        <Route
          exact
          path="/contact-us"
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyContact />
            </React.Suspense>
          }
        />
        <Route exact path="/privacy-policy" element={
          <React.Suspense fallback={<Load/>}>
            <LazyPrivacy/>
          </React.Suspense>
        }
        />
        <Route 
          exact 
          path="/appointment" 
          element={
            <React.Suspense fallback={<Load/>}>
              <LazyAppointment />
            </React.Suspense>
          } 
        />
        <Route exact path="*" element={<Home/>} />
      </Routes>
    </AnimatePresence>
  );
}

import React from 'react'
import {ErrorMessage, useField} from 'formik'

function FormCards({label,design,text,...props}) {

    const [field] = useField(props);
    return (
        <div className={design}>
            <label htmlFor={field.name}>{label}</label>
            <input 
            className={text}
            {...field}{...props}
            required
            />
            <div className="error">
                <ErrorMessage name={field.name}/>
            </div>
        </div>
  )
}

export default FormCards
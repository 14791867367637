import "../styles/Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="conclase">
        <div className="footer-log">
          <Link
            to="/"
            className="conclase-logo"
            onClick="window.location.reload();"
          ></Link>
          <p className="description">
            We provide solutions and services that help companies achieve more.
          </p>
        </div>

        <div className="footer-media">
          <p className="footer-header">Social Media</p>
          <div className="social-media">
            <a
              href="https://www.facebook.com/conclasehq"
              className="media"
              rel="noreferrer"
              target="_blank"
            >
              <div className="media-icon4"></div>
            </a>

            <a
              href="https://www.linkedin.com/company/conclasehq"
              className="media"
              rel="noreferrer"
              target="_blank"
            >
              <div className="media-icon1"></div>
            </a>

            <a
              href="https://mobile.twitter.com/conclasehq"
              className="media"
              rel="noreferrer"
              target="_blank"
            >
              <div className="media-icon2"></div>
            </a>

            <a
              href="https://www.instagram.com/conclasehq"
              className="media"
              rel="noreferrer"
              target="_blank"
            >
              <div className="media-icon3"></div>
            </a>
          </div>
        </div>
      </div>

      <div className="footer-links">
        <div className="company-links">
          <p className="footer-header">Company</p>
          <div className="comp-links">
            <Link to="/about-us" className="f-link">
              About
            </Link>
            <Link to="/product" className="f-link">
              Product
            </Link>
            <Link to="/portfolio" className="f-link">
              Portfolio
            </Link>
            <Link to="/privacy-policy" className="f-link">
              Policies
            </Link>
          </div>
        </div>

        <div className="location">
          <div className="main-locate">
            <p className="footer-header">Africa</p>
            <p className="country">Nigeria</p>
            <p className="city">Lagos:</p>
            <p className="locate">
              No 10. Wole Ogunjimi Street, Opebi, Ikeja, Lagos.
            </p>
            <p className="city">Abuja:</p>
            <p className="locate">
              Suite A9, Mazzado Plaza, Shetimma MongunoCrescent Utako District,
              Abuja.
            </p>
          </div>

          <div className="locate-contact">
            {/* <p className="footer-header">Contact Us</p> */}
            <p className="can-num">+234 (0) 705 285 4847</p>
            <p className="can-num">+234 (0) 703 593 2396</p>
            <p className="can-mail">Hello@conclaseint.com</p>
            {/* <p className="can-mail">Conclaseconsulting.com</p> */}
          </div>
        </div>

        <div className="location">
          <div className="locate-contact f-shape">
            <p className="footer-header">North America</p>
            <p className="country">Canada</p>
            <p className="locate">
              7833 Rosewood St Burnaby, British Columbia Canada V5E 2H2.
            </p>
            <p className="can-num">+1 (778) 288-9846</p>
            <p className="country">U.S.A</p>
            <p className="locate">
              16192 Coastal Highway, Lewes, Delaware 19958, County of Sussex.
            </p>
            {/* <p className="can-num">+1 (778) 288-9846</p> */}
            {/* <p className="can-mail">Hello@conclaseint.com</p> */}
          </div>

          <div className="locate-contact">
            <p className="footer-header">Middle East</p>
            <p className="country">U.A.E</p>
            <p className="locate">
              Business Center 1, M Floor, The Meydan Hotel, Nad Al Sheba, Dubai,
              U.A.E.
            </p>
            <p className="can-num">+971 582 625 411</p>
            {/* <p className="can-mail">Hello@conclaseint.com</p> */}
          </div>
        </div>

        <div className="footer-media option">
          <p className="footer-header">Social Media</p>
          <div className="social-media">
            <a
              href="https://www.facebook.com/conclasehq"
              className="media"
              rel="noreferrer"
              target="_blank"
            >
              <div className="media-icon4"></div>
            </a>
            <a
              href="https://www.linkedin.com/company/conclasehq"
              className="media"
              rel="noreferrer"
              target="_blank"
            >
              <div className="media-icon1"></div>
            </a>
            <a
              href="https://mobile.twitter.com/conclasehq"
              className="media"
              rel="noreferrer"
              target="_blank"
            >
              <div className="media-icon2"></div>
            </a>
            <a
              href="https://www.instagram.com/conclasehq"
              className="media"
              rel="noreferrer"
              target="_blank"
            >
              <div className="media-icon3"></div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

import Cards from "./Cards";
import "../styles/Services.css";

const Services = () => {
  return (
    <div className="service">
      <div className="service-header">
        <div className="maintxt">
          <p className="subtext-1">Our Services</p>
          <p className="subtext-2"></p>
          <p className="subtext-2">
            Providing valuable solutions to your business needs.
            <br /> <br />
            Conclase services are constantly expanding and are divided into
            three main categories.
          </p>
        </div>
      </div>

      <div className="card-grid-1">
        <Cards
          title="Recruiting "
          text="Conclase provides flexible tech hiring solutions to suit different needs and budgets, including direct placement, contract-to-hire, and temporary staffing at a turn-around time of 24-48 hours."
          colour="#E9F6F9"
          icon="logo3"
          locate="/tech_recruiting"
        />

        <Cards
          title="Training"
          text="We teach the most in-demand tech skills in six months and offer internships to skilled individuals with diverse tech backgrounds."
          colour="#FFF6DE"
          icon="logo1"
          locate="/tech_training"
        />
        <Cards
          title="Startup Studio"
          spec="spec"
          text="Conclase designs, builds, and delivers custom software solutions that align with business goals and user needs."
          colour="#F8F3FA"
          locate="/tech_studio"
          icon="logo4"
        />
      </div>
    </div>
  );
};

export default Services;

import Navigation from "./Navigation";
import Content from "./Content";
import Curved from "./Curved";
import "../styles/Container.css";
import {useState} from "react";

function Container({ text, design }) {
  const [rotate, setRotate] = useState(()=>{
    let position = ["2.5", "4", "6", "8", "10", "12", "14", "16", "18", "20", "21", "23", "24", "26", "28", "30","18", "16", "14", "12","10", "8", "6","5", "4", "3" ,"2.5", "2"];
    let x = 0;
    setInterval(()=>{
      setRotate(position[x]);
      x+=1;
      if(x===28){
        x=0;
      }
    },800);
  });

  return (
    <div className="container">
      <Navigation active="active" state="state" logo="logo" mobile="mobile-btn" newcolor="newcolor2"/>
      <div className="contain">
        <Content text={text} design={design} />
        <div className="rotate">
          <Curved rotate={rotate}
          text="R e s o u rc in g  A fric a  fo r th e  W  o rld  . G u id in g  u s e rs  to  v a lu e  in  th e  te c h  w o rld  . "/>
        </div>
        <div className="image"></div>
      </div>
    </div>
  );
}

Container.defaultProps = {
  text: "Solutions",
  design: "#fdc329",
};

export default Container;

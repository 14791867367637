import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import {useState} from "react";

 function Curved({ text,rotate }) {
  const characters = text.split("");
  const degree = 360 / characters.length;
  const [radSize, setRadSize] = useState(250); 

  const reShape = () => {
    if(window.innerWidth<=480){
      setRadSize(160);
    }
    else if(window.innerWidth>=481 && window.innerWidth <= 1140){
      setRadSize(195);
    }
    else if(window.innerWidth>=1141 && window.innerWidth <=1199){
      setRadSize(225);
    }else{
      setRadSize(250);
    }
  };

  useEffect(()=>{
    reShape();
  }, []);

  window.addEventListener("resize", reShape);
  return (
    <h1>
      {characters.map((char, i) => (
        <span
          key={`heading-span-${i}`}
          style={{
            height: `${radSize}px`,
            transform: `rotate(${degree * i - 360 / rotate}deg)`,
            transformOrigin: `0 ${radSize}px 0`,
          }}>
          {char}
        </span>
      ))}
    </h1>
  );
}

Curved.propTypes = {
    text: PropTypes.string.isRequired,
};

Curved.defaultProps = {
  rotate: "2.5"
}

export default Curved;
import "../styles/Subscribe.css";
import React, { useRef, useState } from "react";
import { Formik } from "formik";
import FormCards from "./FormCards";
// import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import axios from "axios";

function Subscribe() {
  const form = useRef(null);

  const [busy, setBusy] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);

    await axios
      .post("https://formspree.io/f/xkgnzjzl", {
        Name: name,
        Email: email,
      })
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "You're in",
        });

        setName("");
        setEmail("");
        setBusy(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: "An error occured",
        });

        setBusy(false);
      });
  };

  return (
    <div className="subscribe">
      <div className="design-1">
        <div className="design-image"></div>
      </div>
      <div className="subscribe-box">
        <div className="subscribe-header">
          <h4>Join Our Mailing List</h4>
          <span>
            Subscribe to our newsletter to stay connected to our activities, get
            insights and updates on what's happening in our community!
          </span>
        </div>

        <Formik>
          {(formik) => (
            <div>
              <form
                className="subscribe-form"
                ref={form}
                onSubmit={handleSubmit}
              >
                <FormCards
                  name="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Full Name"
                  type="text"
                  text="form"
                />
                <FormCards
                  name="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Email Address"
                  type="email"
                  text="form"
                />
                <button className="subscribe-btn" type="submit">
                  {busy ? "Subscribing..." : "Subscribe"}
                </button>
              </form>
            </div>
          )}
        </Formik>
      </div>
      <div className="design-2">
        <div className="design-image1"></div>
      </div>
    </div>
  );
}

export default Subscribe;

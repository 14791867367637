import AboutHeader from "./AboutHeader";
import { Link } from "react-router-dom";
import "../styles/About.css";

const About = () => {
  return (
    <div className="about">
      <div className="head">
        <AboutHeader
          subtitle="About Us"
          text="Conclase is a leading tech company in Africa offering tech recruiting, tech startup studio, and world-class tech education. Our mission is to source Africa's talent and innovation to the world. Over the years, we have built a reputation of excellence, serving some of Africa's biggest companies and helping them stay ahead."
          texts="Our service offerings help companies do tech recruitment right, help entrepreneurs and startups build their Minimum Viable Product (MVP) quickly and efficiently, deploy software solutions and tech projects faster, up-skill their workforce and hit CSR goals. Most importantly, we serve as partners to companies we work with, allowing them to achieve more in their businesses."
          // texts="Over the years, we have
          // built a reputation of excellence, serving some of Africa's biggest companies and helping
          // them stay ahead."
          // texts1="Our service offerings help companies do tech recruitment right, deploy tech projects
          // faster, stand out with world-class websites and software solutions, upskill their workforce
          // and hit CSR goals."
          // texts2="Most importantly, we serve as partners to companies we work with, allowing them to achieve
          // more in their businesses."
          size="width"
        />

        <div>
          <Link to="/about-us" className="abt-link">
            <span className="abt-txt">Read more</span>
          </Link>
        </div>
      </div>

      <div className="image-sec">
        <div className="abt-img"> </div>
        <div className="img-sec-1">1k companies</div>
        <div className="img-sec-2">
          <span>50+ </span>
          <br />
          Projects
        </div>
        <div className="img-sec-3"></div>
      </div>
    </div>
  );
};

export default About;



const AboutHeader = ({subtitle, text,texts,texts1, texts2,design, size, text1,text2,text3,text4,text5,query}) => {
  return (
    <div className="about-header">
          <div className="txt">
            <p className="text-1" id={query} style={{color:design}}>
              {subtitle}<span id="f">{text1}</span>{text2}<span id="g">{text3}</span>
              {text4}<span id="f">{text5}</span>
            </p>
            <div className="space">
              <p className="text-2" style={{color:design}} id={size}>
                {text}
              </p>
              <p className="text-2" style={{color:design}} id={size}>
                {texts}
              </p>
              <p className="text-2" style={{color:design}} id={size}>
                {texts1}
              </p>
            </div>
          </div>
    </div>
  )
}

export default AboutHeader
import "animate.css";
// import { InlineWidget } from 'react-calendly';
import { Link } from "react-router-dom";

const Content = ({ text, design }) => {
  return (
    <div className="content">
      <p className="main-content">
        We take a proactive approach to your IT{" "}
        <span id="robo" style={{ color: design }}>
          {text}.
        </span>
      </p>
      <div className="content-text">
        <span className="sub-content">
          Conclase is a leading tech company in Africa offering tech recruiting,
          tech startup studio, and world-class tech education. Our mission is to
          source Africa's talent and innovation to the world.
        </span>

        <div className="btn-content">
          <Link to="/appointment" className="content-btn">
            <span className="content-link">Request a Demo</span>
          </Link>

          <Link to="/community" className="content-btn-1">
            <div className="btn-img"></div>
            <span className="content-link change">Join our Community</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Content;



const TestCard = ({name,job,text,icon,size,font,back,myw,crew,crew1}) => {
    return ( 
        <div className="my-card" id={myw} style={{ backgroundColor: back}}>
              <div className="test-tag">
                <div className={icon}></div>
                  <div className="test-name">
                    <p className="tag-name" id={crew}>{name}</p>
                    <p className="tag-job" id={crew1}>{job}</p>
                  </div>
              </div>
              <div className="my-card-text" id={font}>
                {text}
              </div>
        </div>
    )
}

export default TestCard
import React, {useRef,useState} from 'react';
import "../styles/Popup.css"
import FormCards from '../components/FormCards';
import {Formik} from "formik";
import emailjs from "emailjs-com";
import Swal from 'sweetalert2';


function Popup(props) {
    const [name,setName] = useState("");
    const [mobile,setMobile] = useState("");
    const [email,setEmail] = useState("");
    const [message,setMessage] = useState("");

    const form = useRef(null);
  return (props.trigger)?(
    <div className="popup">
        <div className="popup-inner">
            <div className="pop-1">
                <div className="pop-img-1"></div>
                
            </div>
            
            <div className="pop-2">
                <span className="close-btn" onClick={()=>props.setTrigger(false)}>X</span>
                <div className="pop-card">
                    <span className="pop-head">
                        Get in Touch
                    </span>

                    <span className="pop-text">
                    Talk to an expert
                    </span>

                    <Formik>
                    {formik => (
                        <div>
                            <form
                             ref={form}
                             onSubmit={(e) => {
                                 e.preventDefault();
                                 emailjs.sendForm('service_u67iu27', 'template_avkjxmj', form.current, 'fhQY2nDt6uUEcX2S6')
                                 .then((result) => {
                                    props.setTrigger(false); 
                                     console.log(result.text);
                                     Swal.fire({
                                         icon: 'success',
                                         title: "Thank you for your message! A member of our Sales & Business Development team will reach out to you soon.",
                                     })         
                                 }, (error) => {
                                     console.log(error.text);
                                     Swal.fire({
                                         icon: "error",
                                         title: "Oops, Something went wrong",
                                         text: error.text,
                                     })
                                 });
                                 setName("");
                                 setMobile("");
                                 setEmail("");
                                 setMessage("");
                                 }
                             }
                            >
                                <FormCards label="First Name" name="name" 
                                placeholder="John Adekunle" value={name} onChange={event => setName(event.target.value)}
                                type="text" text="cont-form" design="pop-form"/>
                                <FormCards label="Mobile" name="mobile" 
                                placeholder="+2347056478453" value={mobile} onChange={event => setMobile(event.target.value)}
                                type="tel" text="cont-form" design="pop-form"/>
                                <FormCards label="Email" name="email" 
                                placeholder="Conclase.africa@gmail.com" value={email} onChange={event => setEmail(event.target.value)}
                                type="email" text="cont-form" design="pop-form"/>
                                <FormCards label="Message" name="message" 
                                placeholder="Type your message" value={message} onChange={event => setMessage(event.target.value)}
                                type="text" text="cont-form-2" design="pop-form"/>
                                <button type="submit" className="form-btn">Send</button>
                            </form>
                        </div>
                    )}
                    </Formik>
                </div>
            </div>
        </div>
    </div>
  ):"";
}

export default Popup
import "../styles/Partners.css";

const Partners = () => {
  return (
    <div className="partners">
      <div className="part-header">
        <div className="maintext">
          <p className="parttext-1">
            Meet Our Partners
          </p>
          <p className="parttext-2">
            We work with highly-rated partners in our quest to consistently deliver value.
          </p>
        </div>
      </div>

      <div className="part-logo">
        <div className="sage"></div>
        <div className="connectwise"></div>
        <div className="microsoft"></div>
        <div className="reddit"></div>
      </div>

      <div className="proj-icons part-logo1">
          <div className="proj-1"></div>
          <div className="proj-2"></div>
          <div className="proj-3"></div>
          <div className="proj-4"></div>
          <div className="proj-5"></div>
        </div>
    </div>
  );
};

export default Partners;

import {BrowserRouter as Router} from "react-router-dom";
import "./App.css";
import AnimatedRouter from "./components/AnimatedRouter";
import ScrollTop from "./functions/ScrollTop";


function App() {
  
  return (
      <Router>
        <ScrollTop/>
        <AnimatedRouter/>
      </Router>
  );
}

export default App;

import {Link} from "react-router-dom";

export default function NavLinks({design, state, page, current, colour, id,text}) {
    return (
      <li className={design} id={state}>
          <Link to={page} className={current} id={id} style={{color:colour}}>
              {text}
          </Link>
      </li>
    )
  }
  
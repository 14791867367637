import "../styles/Product.css";

const Product = () => {
  return (
    <div className="product">
      <div className="prod-header">
        <div className="prodtext">
          <p className="prodtext-1">See some of our solutions</p>
          <p className="prodtext-2">
            Our products help companies achieve more.
          </p>
        </div>
      </div>

      <div className="prod-cards">
        <div className="prod-section">
          <div className="pro-card jobs">
            <div className="card-container">
              <div className="elements">
                <span className="prods-name">Conclase Jobs</span>
                <span className="prods-name-sub">
                  Find your dream job and connect with leading companies.
                </span>
                <a
                  href="https://www.conclasejobs.com"
                  rel="noreferrer"
                  target="_blank"
                  className="card-links"
                >
                  <span className="card-link-span">Go To Product</span>
                </a>
              </div>
            </div>
          </div>
          <div className="body-content">
            <p className="body-head">Conclase Jobs</p>
            <p className="body-sub">
              Conclase Jobs connects tech talents and employers looking to hire
              them. Whether you're looking for a new job or a new hire, Conclase
              Jobs is the place to search.
            </p>
          </div>
        </div>

        <div className="prod-section">
          <div className="pro-card candle">
            <div className="card-container">
              <div className="elements">
                <span className="prods-name">Conclase Academy</span>
                <span className="prods-name-sub">
                  A learning management software to gain in-demand tech skills.
                </span>
                <a
                  href="https://www.conclaseacademy.com"
                  rel="noreferrer"
                  target="_blank"
                  className="card-links"
                >
                  <span className="card-link-span">Go To Product</span>
                </a>
              </div>
            </div>
          </div>
          <div className="body-content">
            <p className="body-head">Conclase Academy</p>
            <p className="body-sub">
              Conclase Academy is an African-based tech school that teaches
              in-demand tech skills, guarantees paid tech internships for its
              graduates, and empowers learners to enter and thrive in the tech
              industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;

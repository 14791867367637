import React from "react"

function Load(){
    return(
        <div className="load">
            <div className="load-img"></div>
        </div>
    );
}

export default Load;
import {Link} from "react-router-dom"
const Cards = ({title,text,colour,icon,spec,locate}) => {
  return (
    <Link to={locate} className="card" style={{background:colour}}>
        <div className="card-icon" >
            <div className={icon}></div>
        </div>
        <span className="card-txt" id={spec}>
            Tech. <br/> {title}
        </span>
        <span className="card-content">
            {text}
        </span>
    </Link>
  )
}

export default Cards
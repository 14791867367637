import { Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import { useState } from "react";

function Navigation({ color, colour, logo, mobile, newcolor, ...props }) {
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <nav
      className={toggle ? "nav-bar expanded" : "nav-bar"}
      style={{ backgroundColor: color }}
    >
      <Link to="/" className="mask" onClick="window.location.reload();">
        <div className={logo}></div>
      </Link>

      <div className="wrapper">
        <ul className="links">
          <NavLinks
            design="nav-link"
            page="/"
            current="link-text"
            text="Home"
            state={props.state}
            id={props.active}
            colour={colour}
          />
          <li className="nav-link1" id={props.state1}>
            <span
              className="link-text1"
              style={{ color: colour }}
              id={props.active1}
            >
              About
            </span>
            <ul>
              <li>
                <Link to="/about-us" id={newcolor}>
                  Who we are
                </Link>
              </li>
              <li>
                <a
                  href="https://conclaseint.com/blog"
                  rel="noreferrer"
                  id={newcolor}
                >
                  Blog
                </a>
              </li>
              <li>
                <a
                  href="https://conclasejobs.com"
                  rel="noreferrer"
                  id={newcolor}
                >
                  Career
                </a>
              </li>
              <li>
                <Link to="/faq" id={newcolor}>
                  FAQs
                </Link>
              </li>
            </ul>
          </li>
          <NavLinks
            design="nav-link2"
            page="/product"
            current="link-text2"
            text="Product"
            state={props.state2}
            id={props.active2}
            colour={colour}
          />
          <li className="nav-link3" id={props.state3}>
            <span
              className="link-text3"
              style={{ color: colour }}
              id={props.active3}
            >
              Services
            </span>
            <ul>
              <li>
                <Link to="/tech_recruiting" id={newcolor}>
                  Tech Recruiting
                </Link>
              </li>
              <li>
                <Link to="/tech_training" id={newcolor}>
                  Tech Training
                </Link>
              </li>
              <li>
                <Link to="/tech_studio" id={newcolor}>
                  Tech Startup Studio
                </Link>
              </li>
            </ul>
          </li>
          <NavLinks
            design="nav-link4"
            page="/portfolio"
            current="link-text4"
            text="Portfolio"
            state={props.state4}
            id={props.active4}
            colour={colour}
          />
        </ul>

        <Link to="/contact-us" className="contact mobile-contact">
          <span className="link-text6" id={newcolor}>
            Contact us
          </span>
        </Link>
      </div>

      <div className="one" onClick={handleToggle}>
        {toggle ? (
          <div className="cancel">X</div>
        ) : (
          <div className={mobile}></div>
        )}
      </div>
    </nav>
  );
}

export default Navigation;

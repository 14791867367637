import "../styles/Testimonial.css";
import TestCard from "./TestCard";

const Testimonial = () => {
  return (
    <div className="testimonial">
      <div className="test-head">
        <div className="part-header">
          <div className="maintext">
            <p className="test-text-1">Why Conclase? Hear from our clients</p>
            <p className="parttext-2">
              We don't blow our trumpets. We'll let our happy clients do the blowing.
            </p>
          </div>
        </div>
      </div>

      <div className="test-cards">
        <TestCard
          name="Vuakpor Ojo"
          job="COO, GIG"
          icon="ojo"
          text="Conclase provides us with tech resources to work on our projects
            and also help us manage some of our tech services effectively."
        />

        <TestCard
          name="Chukwuma Okoli"
          job="Group Head, Digital Delivery, Fidelity Bank"
          text="We have always trusted Conclase with helping us 
            hire the right resources for our projects and they always deliver."
          icon="okoli"
        />

        <TestCard
          name="Oluremi Oladipupo"
          job="Head of Software Engineering, FCMB"
          icon="tayo"
          text="Conclase has helped in making our recruitment of technical resources 
          easy and seemless."
        />

        <TestCard
          name="Debo Atayero"
          job="Project Management Office, Zenith Bank"
          text="We believe in using technology to drive growth and 
            we contacted Conclase to help us optimize our current processes. 
            They worked with us to achieve that and have made our processes better."
          icon="usman"
        />

        <TestCard
          name="Tayo Osikoya"
          job="CEO Eyeconic"
          text="As a forward thinking company, we turned to Conclase to make an app that would
            make outdoor advertising easy and effective for our clients and they delivered."
          icon="michael"
        />
      </div>
    </div>
  );
};

export default Testimonial;
